import React, { useState } from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Subheading from "./../components/Subheading"
import Pagination from "./../components/Pagination"
import FeaturedCard from "./../components/Cards/News"
import NewsCards from "./../components/Cards/NewsList"
import { graphql } from "gatsby"

export default ({
  data: {
    pageInfo: { title, description },
    newsDescendingOrder: { nodes: newsDescendingOrder },
  },
}) => {
  const newsPerPage = 9
  const newsPagesCount = Math.ceil(newsDescendingOrder.length / newsPerPage)
  const getNewsItems = sortBy =>
    sortBy === "newest"
      ? newsDescendingOrder
      : [...newsDescendingOrder].reverse()
  const [sortBy, setSortBy] = useState("newest")
  const [currentPageNewsItems, setCurrentPageNewsItems] = useState([
    ...getNewsItems(sortBy).slice(1, newsPerPage + 1),
  ])

  return (
    <Layout pageInfo={{ pageName: "In The News" }}>
      <SEO title="In The News" keywords={[`gatsby`, `react`, `bootstrap`]} />
      <Subheading title={title}>{description}</Subheading>
      <Pagination
        sortMode={sortBy}
        onClickSort={sortBy => {
          setSortBy(sortBy)
          setCurrentPageNewsItems([
            ...getNewsItems(sortBy).slice(1, newsPerPage + 1),
          ])
        }}
        urlPrefix={`/in-the-news`}
        currentPage={1}
        numPages={newsPagesCount}
      />
      <FeaturedCard {...getNewsItems(sortBy)[0]} isFeatured={true} />
      <NewsCards items={currentPageNewsItems} />
      <Pagination
        sorting={false}
        urlPrefix={`/in-the-news`}
        currentPage={1}
        numPages={newsPagesCount}
      />
    </Layout>
  )
}

export const InTheNewsQuery = graphql`
  query InTheNewsQuery {
    pageInfo: allNodeNews {
      nodes {
        title
        caption: field_caption
      }
    }
    newsDescendingOrder: allNodeNews(sort: { order: DESC, fields: [created] }) {
      nodes {
        id
        title
        caption: field_caption
        source: field_source
        published: created(formatString: "MMMM D, YYYY")
        link: field_link {
          uri
        }
        relationships {
          field_image {
            relationships {
              field_media_image {
                thumbnail: localFile {
                  childImageSharp {
                    fixed {
                      width
                      height
                      originalName
                      src
                      srcSet
                    }
                  }
                }
                featuredImage: localFile {
                  childImageSharp {
                    fixed(width: 1108, height: 444) {
                      width
                      height
                      src
                      srcSet
                      aspectRatio
                      originalName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
